/* 移动设备 */
@media screen and (max-width: 539px) {
  .footer {
    padding: 20px 0 20px 0;
    background-image: url("../../assets/img/footer-bg.png");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-top {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .footer-item {
    padding: 10px;
    box-sizing: content-box;
  }

  .footer img {
    width: 26%;
  }
  .footer p {
    cursor: pointer;
    display: flex;
    font-weight: 400;
    font-size: 12px;
    color: #b8b8b8;
    margin-top: 20px;
  }

  .footer-title {
    font-size: 20px;
  }

  .footer-link {
    cursor: pointer;
  }

  .footer-bottom {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
  }
  /* 左右横线穿过 */
  .footer-name {
    font-size: 22px;
    line-height: 20px;
  }
  .footer-name:after,
  .footer-name:before {
    position: absolute;
    top: 15%;
    background: white;
    content: "";
    height: 1px;
    width: 35%;
  }
  .footer-name:before {
    left: -5px;
  }
  .footer-name:after {
    right: -5px;
  }
}

/* 其它设备 */
@media screen and (min-width: 540px) {
  .footer {
    padding: 20px 0 20px 0;
    background-image: url("../../assets/img/footer-bg.png");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-top {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
  }

  .footer-item {
    padding: 20px;
    /* box-sizing: content-box; */
    width: 30%;
  }

  .footer img {
    width: 26%;
  }
  .footer p {
    cursor: pointer;
    display: flex;
    align-items: end;
    font-weight: 400;
    font-size: 18px;
    color: #b8b8b8;
    letter-spacing: 1px;
    margin-top: 20px;
  }

  .footer-title {
    font-size: 34px;
  }

  .footer-link {
    cursor: pointer;
  }

  .footer-bottom {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    font-size: 18px;
  }
  /* 左右横线穿过 */
  .footer-name {
    font-size: 36px;
    line-height: 20px;
  }
  .footer-name:after,
  .footer-name:before {
    position: absolute;
    top: 12%;
    background: white;
    content: "";
    height: 1px;
    width: 40%;
  }
  .footer-name:before {
    left: 15px;
  }
  .footer-name:after {
    right: 15px;
  }
  .footer-link {
    color: #4979ff;
  }
}
