/* 移动设备 */
@media screen and (max-width: 539px) {
  .login-modal {
    margin-top: 30px;
    z-index: 999999;
    display: flex !important;
    justify-content: center;
  }
  .login-modal .modal-dialog {
    max-width: 1000px !important;
    width: 330px !important;
    height: 420px !important;
    z-index: 999999;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .login-modal .modal-content {
    width: 100%;
    height: 100%;
    padding: 15px 10px;
    color: black;
    box-sizing: content-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 0px;
  }
  .login-tip {
    position: absolute;
    left: 10px;
    bottom: 10px;
    font-size: 12px;
    letter-spacing: 1px;
    width: 95%;
    color: gray;
  }
  .login-icon {
    display: none;
    /* position: absolute;
    left: 0;
    top: 0;
    width: 70px;
    height: 70px;
    background: linear-gradient(
      to top left,
      #fff 0%,
      #fff 49.9%,
      rgb(203, 203, 240) 50%,
      rgb(156, 156, 206) 100%
    ); */
  }
  .login-icon-text {
    display: none;
    /* position: absolute;
    left: 10px;
    top: 10px;
    color: white;
    font-size: 12px; */
  }
  .login-left {
    display: none;
  }
  .login-modal .close-btn {
    font-size: 28px;
    color: gray;
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
  }
  .scan-container {
    position: absolute;
    top: 40px;
    left: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .scan-container h5 {
    font-size: 26px;
    letter-spacing: 1px;
    margin-bottom: 0px;
  }
  .scan-img {
    width: 230px;
    height: 230px;
  }
  .login-right {
    width: 100%;
    height: 80%;
    padding: 10px 10px 10px 15px;
    box-sizing: content-box;
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  .login-method span {
    margin-right: 40px;
    letter-spacing: 1px;
    cursor: pointer;
    font-size: 16px;
  }
  .login-method .choosen-method {
    color: rgb(110, 88, 255);
    border-bottom: 3px solid rgb(98, 74, 255);
    padding-bottom: 3px;
  }

  /* 原CSS */
  .login-modal .modal-content .form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .login-field {
    position: relative;
    width: 300px;
    height: 30px;
    margin: 35px 0px 0px 0px;
  }
  .login-field:last-child {
    margin-top: 60px;
  }
  .login-protocol {
    display: flex;
    align-items: center;
  }
  .login-protocol span {
    margin-left: 5px;
    font-size: 12px;
    text-align: center;
  }
  .login-protocol span:last-child {
    cursor: pointer;
    font-size: 12px;
    margin: 0;
    color: rgb(110, 88, 255);
    text-align: center;
  }
  .login-protocol input {
    width: 15px;
    height: 15px;
  }
  .login-input {
    width: 100%;
    height: 100%;
    background: none;
    border-bottom: 1px solid rgba(108, 105, 105, 0.5);
    border-left: 0px;
    border-top: 0px;
    border-right: 0px;
    color: #21293a;
    font-size: 16px;
    border-radius: 0px;
  }
  .input-invalid {
    border-bottom: 1px solid red !important;
  }
  /* 表单校验消息 */
  .login-validMsg {
    display: none;
  }
  .login-invalidMsg {
    position: absolute;
    color: red;
    right: 46%;
    bottom: -25px;
    font-size: 12px;
    width: 160px;
  }
  .sendcode-btn {
    position: absolute;
    bottom: 8px;
    right: 15px;
    font-size: 16px;
    color: gray;
    letter-spacing: 1px;
    padding-left: 20px;
    border-left: 0.5px solid rgba(108, 105, 105, 0.5);
  }
  .login-btn {
    width: 100%;
    height: 50px;
    border: 1px solid rgb(107, 107, 157);
    background-color: rgb(107, 107, 157);
    color: white;
    letter-spacing: 3px;
    font-size: 20px;
    margin-top: 10px;
  }
}

/* 其它设备 */
@media screen and (min-width: 540px) {
  .login-modal {
    z-index: 999999;
  }
  .login-modal .modal-dialog {
    max-width: 1000px !important;
    width: 984px !important;
    height: 548px !important;
    z-index: 999999;
  }
  .login-modal .modal-content {
    width: 100%;
    height: 100%;
    padding: 10px;
    color: black;
    box-sizing: content-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 0px;
  }
  .login-tip {
    position: absolute;
    left: 30%;
    bottom: 10px;
    font-size: 14px;
    letter-spacing: 1px;
    color: gray;
  }
  .login-icon {
    position: absolute;
    right: 0;
    top: 0;
    width: 70px;
    height: 70px;
    background: linear-gradient(
      to top right,
      #fff 0%,
      #fff 49.9%,
      rgb(203, 203, 240) 50%,
      rgb(156, 156, 206) 100%
    );
  }
  .login-icon-text {
    position: absolute;
    right: 10px;
    top: 10px;
    color: white;
    font-size: 12px;
  }
  .login-left {
    width: 445px;
    height: 80%;
    position: relative;
    border-right: 0.5px solid rgba(108, 105, 105, 0.5);
    box-sizing: content-box;
  }
  .login-modal .close-btn {
    font-size: 32px;
    color: gray;
    position: absolute;
    top: 18px;
    left: 32px;
    cursor: pointer;
  }
  .scan-container {
    margin-top: 10px;
    width: 100%;
    height: 100%;
    /* position: absolute;
    top: 40px;
    left: 25%; */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .login-right {
    flex-grow: 1;
    height: 80%;
    padding: 10px 30px 10px 60px;
    box-sizing: content-box;
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  .login-method span {
    margin-right: 40px;
    letter-spacing: 1px;
    cursor: pointer;
  }
  .login-method .choosen-method {
    color: rgb(110, 88, 255);
    border-bottom: 3px solid rgb(98, 74, 255);
    padding-bottom: 3px;
  }

  /* 原CSS */
  .login-modal .modal-content .form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .login-field {
    position: relative;
    width: 400px;
    height: 40px;
    margin: 35px 0px 0px 0px;
  }
  .login-field:last-child {
    margin-top: 70px;
  }
  .login-protocol {
    display: flex;
    align-items: center;
  }
  .login-protocol span {
    margin-left: 10px;
    font-size: 14px;
    text-align: center;
  }
  .login-protocol span:last-child {
    cursor: pointer;
    font-size: 14px;
    margin: 0;
    color: rgb(110, 88, 255);
    text-align: center;
  }
  .login-protocol input {
    width: 20px;
    height: 20px;
  }
  .login-input {
    width: 100%;
    height: 100%;
    background: none;
    border-bottom: 1px solid rgba(108, 105, 105, 0.5);
    border-left: 0px;
    border-top: 0px;
    border-right: 0px;
    color: #21293a;
    font-size: 16px;
  }
  .input-invalid {
    border-bottom: 1px solid red !important;
  }
  /* 表单校验消息 */
  .login-validMsg {
    display: none;
  }
  .login-invalidMsg {
    position: absolute;
    color: red;
    right: 60%;
    bottom: -25px;
    font-size: 12px;
    width: 160px;
  }
  .sendcode-btn {
    position: absolute;
    bottom: 8px;
    right: 15px;
    font-size: 16px;
    color: gray;
    letter-spacing: 1px;
    padding-left: 20px;
    border-left: 0.5px solid rgba(108, 105, 105, 0.5);
  }
  .login-btn {
    width: 100%;
    height: 50px;
    border: 1px solid rgb(107, 107, 157);
    background-color: rgb(107, 107, 157);
    color: white;
    letter-spacing: 3px;
    font-size: 20px;
    margin-top: 10px;
  }
}
