/* 移动设备 */
@media screen and (max-width: 539px) {
  .contact {
    padding: 15px 0 70px 0;
  }
  .contact img {
    width: 90%;
    margin-top: 80px;
  }
  .contact .row {
    display: flex;
    justify-content: start;
    padding-left: 40px;
    width: 100%;
  }
  .contact .contact-title {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 30px;
    letter-spacing: 2.5px;
    color: #567bf7;
    width: 85%;
  }
  .contact form input,
  .contact form textarea {
    width: 85%;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    color: #fff;
    margin: 0 0 8px 0;
    padding: 12px 18px;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.8px;
    transition: 0.3s ease-in-out;
  }
  .contact form input:focus,
  .contact form textarea:focus {
    background: rgba(255, 255, 255, 0.5);
    color: #121212;
  }
  .contact form input::placeholder,
  .contact form textarea::placeholder {
    font-size: 16px;
    font-weight: 400;
    color: grey;
  }
  .contact form input:focus::placeholder,
  .contact form textarea:focus::placeholder {
    color: #121212;
    opacity: 0.8;
  }
  .contact form button {
    font-weight: 700;
    color: white;
    background-color: #567bf7;
    padding: 12px 18px;
    font-size: 18px;
    border-radius: 5px;
    position: relative;
    transition: 0.3s ease-in-out;
  }
  .contact .contact-icon {
    margin-right: 6px;
    padding-bottom: 2.5px;
  }
  .contact form button span {
    z-index: 1;
    position: relative;
  }
  .contact-btn-container {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
  }
  .contact-btn {
    width: 180px;
    border-radius: 5px;
  }
  .apply-msg {
    padding: 0;
    margin: 25px 0 0 0;
    font-size: 22px;
  }
  .bottom-pic {
    display: flex;
    justify-content: center;
  }
}

/* 其它设备 */
@media screen and (min-width: 540px) {
  .contact {
    padding: 60px 80px 200px 120px;
    display: flex;
    align-items: center;
  }
  .contact img {
    width: 90%;
    margin-left: 40px;
  }
  .contact h2 {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 30px;
    letter-spacing: 2.5px;
    color: #567bf7;
  }
  .contact form input,
  .contact form textarea {
    width: 85%;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    color: black;
    margin: 0 0 8px 0;
    padding: 14px 24px;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.8px;
    transition: 0.3s ease-in-out;
  }
  .contact form input:focus,
  .contact form textarea:focus {
    background: rgba(255, 255, 255, 0.5);
    color: #121212;
  }
  .contact form input::placeholder,
  .contact form textarea::placeholder {
    font-size: 16px;
    font-weight: 400;
    color: grey;
  }
  .contact form input:focus::placeholder,
  .contact form textarea:focus::placeholder {
    color: #121212;
    opacity: 0.8;
  }
  .contact form button {
    width: 180px;
    font-weight: 700;
    color: white;
    background-color: #567bf7;
    padding: 14px 35px;
    font-size: 18px;
    margin-top: 25px;
    border-radius: 0;
    position: relative;
    transition: 0.3s ease-in-out;
  }
  .contact .contact-icon {
    margin-right: 6px;
    padding-bottom: 2.5px;
  }
  .contact form button span {
    z-index: 1;
    position: relative;
  }
  .apply-msg {
    padding: 0;
    margin: 25px 0 0 0;
    font-size: 22px;
  }
}
