/* 移动设备(宽度小于540px) */
@media screen and (max-width: 539px) {
  .card {
    width: 100%;
    height: 380px;
    display: flex;
    flex-direction: column !important;
    justify-content: start;
    align-items: center;
    background-color: transparent;
    border: none;
  }
  .card-text {
    margin: 15px 0px;
    width: 85% !important;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 80%;
    height: auto;
  }
  .card-title {
    font-style: normal;
    font-weight: 900;
    font-size: 34px;
    line-height: 40px;
    letter-spacing: 2px;
    color: #567bf7;
  }
  .card-content {
    font-style: normal;
    font-weight: 400;
    font-size: 12.7466px;
    line-height: 18px;
    text-align: justify;
    color: #333333;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 10;
  }
  .card-btn {
    display: none !important;
  }
  .pic {
    width: 85%;
    height: auto;
    /* padding: 15px; */
    box-sizing: content-box;
  }
}

/* 其它设备 */
@media screen and (min-width: 540px) {
  .card {
    width: 100%;
    height: 650px;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    display: flex;
    border: none;
  }

  .card-text {
    padding: 30px 45px 0px 45px;
    width: 40%;
    height: 500px;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    margin: 0px 15px 0px 15px;
  }

  .card-text button {
    color: #567bf7;
  }

  .card-text .card-title {
    font-size: 48px;
    font-weight: 900;
    line-height: 67px;
    letter-spacing: 1px;
    color: #567bf7;
  }

  .card-text .card-content {
    color: #333333;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 10;
    line-height: 160%;
    letter-spacing: 1px;
  }

  .card-btn {
    margin: 45px 0px !important;
  }

  .pic {
    width: 800px;
    height: 470px;
    margin: 0px 50px 0px 50px;
  }
}
