/* 移动端样式 */
@media screen and (max-width: 539px) {
  .navbar {
    background: transparent;
    width: 100%;
  }
  /* 左侧Logo相关 */
  .logo-container {
    display: flex;
    justify-content: start;
    align-items: center;
    height: 100%;
    margin-top: 10px;
  }
  .logo {
    width: 23px;
    height: 14px;
    margin: 0 3px 2px 10px;
  }
  .logo-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #333333;
  }
  .apply {
    margin-top: 10px;
    margin-right: 10px;
    text-decoration: none;
    cursor: pointer;
    font-size: 10px;
  }
}

/* 其它设备样式 */
@media screen and (min-width: 540px) {
  .navbar {
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(3px);
    width: 100%;
  }
  .apply {
    margin-right: 10px;
    text-decoration: none;
    cursor: pointer;
  }

  /* 左侧Logo相关 */
  .logo-container {
    display: flex;
    justify-content: start;
    align-items: center;
    height: 100%;
  }
  .logo {
    width: 45px;
    height: 27px;
    margin: 0 8px 0 10px;
  }
  .logo-text {
    font-weight: 400;
    font-size: 24px;
    line-height: 34px;
    color: #333333;
  }
}

.top-bar {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem 1rem 0.2rem 1rem;
}

.navbar-btn {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #333333;
}
.nav-icon {
  margin-left: 5px;
  padding-bottom: 3px;
}
