/* 移动端样式 */
@media screen and (max-width: 539px) {
  .mask {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .banner .banner-container {
    width: 96%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .banner .title {
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 45px;
    color: #567bf7;
    position: relative;
    letter-spacing: 1px;
    margin-bottom: 2.5px;
    padding-top: 25px;
    display: block;
  }
  .banner .cover {
    -webkit-user-select: none; /*webkit浏览器*/
    -moz-user-select: none; /*火狐*/
    -ms-user-select: none; /*IE10*/
    user-select: none;
    font-style: normal;
    font-weight: 900;
    font-size: 70px;
    line-height: 100px;
    letter-spacing: 18px;
    color: #ffffff;
    opacity: 0.2;
    position: absolute;
    width: 100%;
    height: 75px;
    top: -10px;
    left: 52%;
    transform: translateX(-50%);
  }
  .banner .desc {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #111111;
    opacity: 0.9;
  }
  .vvd {
    width: 145px;
    height: 45px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 20px;
    gap: 7.7px;
    background: #ffffff;
    border: 0.6px solid #567bf7;
    box-shadow: 0px 0px 7px rgba(25, 76, 151, 0.05),
      1.2px 1.2px 7.08795px rgba(124, 26, 222, 0.08);
    border-radius: 3.2px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #567bf7;
    margin: 80px 5px 0 5px;
  }
  .applied-btn {
    width: 145px;
    height: 45px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 20px;
    gap: 7.7px;
    background: rgba(47, 87, 231, 0.12);
    border: 1px solid #567bf7;
    box-shadow: 0px 0px 12px rgba(25, 76, 151, 0.05),
      2px 2px 11px rgba(124, 26, 222, 0.08);
    border-radius: 3.2px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #567bf7;
    margin: 80px 5px 0 5px;
  }
  .url-group {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: -50px;
  }
  .url-item a {
    font-size: 15px;
    font-weight: 500;
    color: #567bf7 !important;
    margin: 5px;
  }
  .url-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 10px;
    width: 120px;
    z-index: 999999;
    text-align: center;
  }
}

/* 其它设备样式 */
@media screen and (min-width: 540px) {
  .mask {
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .vvd {
    font-weight: 500;
    font-size: 20px;
    line-height: 30.8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 40px;
    gap: 12px;
    width: 220px;
    height: 60px;
    color: #567bf7;
    background: #ffffff;
    border: 1px solid #567bf7;
    box-shadow: 0px 0px 12px rgba(25, 76, 151, 0.05),
      2px 2px 11px rgba(124, 26, 222, 0.08);
    border-radius: 5px;
    margin: 64px 35px 0px 35px;
  }
  .applied-btn {
    font-weight: 500;
    font-size: 20px;
    line-height: 30.8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 40px;
    gap: 12px;
    width: 220px;
    height: 60px;
    color: #567bf7;
    background: rgba(47, 87, 231, 0.12);
    border: 1px solid #567bf7;
    box-shadow: 0px 0px 12px rgba(25, 76, 151, 0.05),
      2px 2px 11px rgba(124, 26, 222, 0.08);
    border-radius: 5px;
    margin: 64px 35px 0px 35px;
  }
  .url-group {
    display: flex;
    align-items: center;
    position: absolute;
    left: 130px;
  }
  .url-item a {
    font-size: 20px;
    font-weight: 500;
    color: #567bf7 !important;
    margin: 5px;
  }
  .url-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 135px 0px 0px;
    min-width: 160px;
    z-index: 999999;
  }
  .banner .title {
    color: #567bf7;
    position: relative;
    font-style: normal;
    font-weight: 900;
    font-size: 74px;
    line-height: 104px;
    margin-bottom: 2.5px;
    padding-top: 25px;
    display: block;
  }
  .banner .cover {
    -webkit-user-select: none; /*webkit浏览器*/
    -moz-user-select: none; /*火狐*/
    -ms-user-select: none; /*IE10*/
    user-select: none;
    width: 1038px;
    height: 308px;
    position: absolute;
    font-style: normal;
    font-weight: 900;
    font-size: 220px;
    line-height: 308px;
    letter-spacing: 30px;
    color: #ffffff;
    opacity: 0.2;
    top: -75px;
    left: 50%;
    transform: translateX(-50%);
  }
  .banner .desc {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.05em;
    color: #111111;
    opacity: 0.9;
  }
}

.banner {
  margin-top: 0;
  padding: 6rem 0 0 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mask video {
  width: 85%;
  height: 85%;
  /* border: 1px solid black; */
}

.banner .customize-stack {
  justify-content: center;
}

.banner .banner-container {
  position: relative;
  text-align: center;
}

.banner-icon {
  color: #567bf7;
  font-weight: 500;
}
