/************ Custom Font ************/
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Book.ttf");
  font-weight: 400;
}

/************ Variable Css ************/
:root {
  --primary-color: #567bf7;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
  font-size: 20px;
}

/* 移动端宽高 */
@media screen and (max-width: 539px) {
  html {
    font-size: 10px;
  }
  body {
    width: 100%;
  }
}

body {
  font-weight: 400;
  overflow-x: auto;
  background: linear-gradient(
      to right,
      rgba(32, 148, 255, 0.2) 10%,
      rgba(17, 100, 255, 0.2) 46%,
      rgba(169, 102, 255, 0.35) 90%
    ),
    #ffffff;
  color: #fff !important;
  font-family: "Centra", sans-serif !important;
}

.App {
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none !important;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

@media (min-width: 1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}

.success {
  color: green;
}

.danger {
  color: red;
}

/* 移动端样式 */
@media screen and (max-width: 539px) {
  .alert {
    position: absolute;
    top: 20px;
    left: 17%;
    width: 260px;
    padding: 10px 20px 10px 20px !important;
    display: flex;
    justify-content: center;
    letter-spacing: 3px;
    z-index: 99999999;
    font-size: 14px;
  }

  .alert-success {
    color: white !important;
    background-color: rgb(80, 191, 255) !important;
  }
}

/* 其它设备样式 */
@media screen and (min-width: 540px) {
  .alert {
    position: absolute !important;
    top: 60px;
    left: 33%;
    width: 400px;
    height: 50px !important;
    padding: 6px !important;
    display: flex;
    justify-content: center;
    letter-spacing: 2px;
    z-index: 99999999;
  }
  .alert-success {
    color: white !important;
    background-color: rgb(80, 191, 255) !important;
  }
}
