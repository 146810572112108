/* 移动端 */
@media screen and (max-width: 539px) {
  .main {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .sense-container {
    position: relative;
    height: 35rem;
    width: 100%;
  }
  .sense {
    width: 220px;
    height: 190px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;
  }
  .sense-bg {
    position: absolute;
    width: 100%;
    height: 245.91px;
    left: 50%;
    top: 55%;
    z-index: 9999;
    transform: translate(-50%, -50%);
  }
}

/* 其它设备 */
@media screen and (min-width: 540px) {
  .main {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .sense-container {
    padding: 20px 0 20px 0;
    box-sizing: content-box;
    margin-top: 45px;
    position: relative;
    height: 700px;
    width: 100%;
  }
  .sense {
    width: 785px;
    height: 700px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-51%, -53.5%);
    z-index: 99999;
  }
  .sense-bg {
    position: absolute;
    width: 1379px;
    height: 776px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
