/* 移动设备 */
@media screen and (max-width: 539px) {
  .logou-modal {
    margin-top: 80px;
  }
  .logou-modal .modal-header {
    padding: 5px !important;
  }
  .logou-modal .modal-dialog {
    max-width: 320px !important;
    margin: 0px auto;
  }
  .logou-modal .modal-content {
    color: black;
    padding: 10px;
    border: none !important;
  }
  .logou-modal .modal-body {
    height: 40px;
    padding: 12px 15px 10px 15px !important;
    box-sizing: content-box;
    font-size: 16px;
  }
  .logou-modal .modal-footer {
    border: none !important;
    padding: 0px !important;
  }
}

/* 其它设备 */
@media screen and (min-width: 540px) {
  .logou-modal .modal-header {
    padding: 5px !important;
  }
  .logou-modal .modal-dialog {
    max-width: 400px !important;
  }
  .logou-modal .modal-content {
    color: black;
    padding: 10px;
    border: none !important;
  }
  .logou-modal .modal-body {
    height: 40px;
    padding: 15px !important;
    box-sizing: content-box;
  }
  .logou-modal .modal-footer {
    border: none !important;
    padding: 0px !important;
  }
}
